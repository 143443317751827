import { itemHelper } from '../helpers'

export const ACTIONS = {
  MARK_ITEMS_AS_READ_LOCAL: 'ITEM/MARK_ITEMS_AS_READ_LOCAL',
  MARK_ITEMS_AS_READ: 'ITEM/MARK_ITEMS_AS_READ',
  CLEAR_COMPLETED_ATTENTION_NEEDED_ITEMS: 'ITEM/CLEAR_COMPLETED_ATTENTION_NEEDED_ITEMS',
  CLEAR_ALL_ATTENTION_NEEDED_ITEMS: 'ITEM/CLEAR_ALL_ATTENTION_NEEDED_ITEMS',
  CLEAR_OLDER_ALERTS: 'ITEM/CLEAR_OLDER_ALERTS',
  CLEAR_CATEGORY_ALERTS: 'ITEM/CLEAR_CATEGORY_ALERTS',
}

export const localMarkItemsAsReadFromIDs = serverIDs => {
  return (
    serverIDs && {
      type: ACTIONS.MARK_ITEMS_AS_READ_LOCAL,
      payload: serverIDs,
    }
  )
}

export const markItemAsReadFromIDs = serverIDs => {
  return (
    serverIDs && {
      type: ACTIONS.MARK_ITEMS_AS_READ,
      payload: serverIDs,
    }
  )
}

export const markItemAsReadFromID = serverID => {
  return serverID && markItemAsReadFromIDs([serverID])
}

export const markItemsAsRead = items => {
  if (!items) {
    return
  }
  const serverIDs = items.map(itemHelper.getId)
  return markItemAsReadFromIDs(serverIDs)
}

export const markItemAsRead = item => {
  return item && markItemsAsRead([item])
}

export const clearCompletedAttentionNeededItems = () => ({ type: ACTIONS.CLEAR_COMPLETED_ATTENTION_NEEDED_ITEMS })
export const clearAllAttentionNeededItems = () => ({ type: ACTIONS.CLEAR_ALL_ATTENTION_NEEDED_ITEMS })
export const clearSevenDaysAlerts = () => ({ type: ACTIONS.CLEAR_OLDER_ALERTS, payload: { days: 7 } })
export const clearOneDayAlerts = () => ({ type: ACTIONS.CLEAR_OLDER_ALERTS, payload: { days: 1 } })

// Category-specific alert clearing actions
export const clearDelegationAlerts = () => ({ type: ACTIONS.CLEAR_CATEGORY_ALERTS, payload: { category: 'delegation' } })
export const clearInvitationAlerts = () => ({ type: ACTIONS.CLEAR_CATEGORY_ALERTS, payload: { category: 'invitation' } })
export const clearMentionAlerts = () => ({ type: ACTIONS.CLEAR_CATEGORY_ALERTS, payload: { category: 'mention' } })
export const clearOverdueAlerts = () => ({ type: ACTIONS.CLEAR_CATEGORY_ALERTS, payload: { category: 'overdue' } })
export const clearOtherAlerts = () => ({ type: ACTIONS.CLEAR_CATEGORY_ALERTS, payload: { category: 'other' } })
