import { useCallback, useEffect } from 'react'
import _ from 'lodash'
import { FILTER_REDUCER_KEYS } from '../common/src/reducers/filtersKeys'
import { bindActionCreators } from 'redux'
import { useStore, useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { 
  getItemsTagsFilter,
  getItemsStateFilter,
  getItemsQuadrantFilter,
  getItemsOwnersFilter,
  getItemsSortType,
  getItemsSortAscending,
  getProjectsTagsFilter,
  getProjectsTagsModeFilter,
  getProjectsSortType
} from '../common/src/selectors/filtersSelectors'
import { 
  setItemsTagsFilter, 
  setProjectsTagsFilter, 
  setItemsTextFilter, 
  setItemsStateFilter, 
  setItemsOwnerFilter,
  setItemProjectFilter,
  setItemsQuadrantFilter,
  setItemsSortType,
  setItemsSortAscending,
  setProjectsTagsModeFilter,
  setProjectsSortType,
  setItemProjectsMode,
  STATE_FILTER,
  PROJECTS_MODE_KEYS
} from '../common/src/actions/filtersActions'
import { itemHelper } from '../common/src/helpers'
import * as queryParamsHelper from '../helpers/queryParamsHelper'

// Helper function to update URL with proper encoding
const updateUrlParam = (url, key, value) => {
  try {
    // Work with a copy of the current URL's search params
    const searchParams = new URLSearchParams(url.search);
    
    if (value) {
      // Set with native URL methods to ensure proper encoding
      searchParams.set(key, value);
    } else {
      searchParams.delete(key);
    }
    
    // Use the standard URL API to create the new URL properly
    const newSearch = searchParams.toString();
    const newUrl = `${url.origin}${url.pathname}${newSearch ? `?${newSearch}` : ''}`;
    window.history.replaceState(null, '', newUrl);
  } catch (error) {
    console.error('Error updating URL parameter:', error);
  }
}

// Helper function to update multiple URL parameters
// Export this for use in other files
export const updateMultipleUrlParams = (params) => {
  try {
    const url = new URL(window.location.href)
    
    // Apply all parameter updates with validation
    Object.entries(params).forEach(([key, value]) => {
      // Validate key - only allow letters, numbers, and underscores for parameter names
      if (!/^[a-zA-Z0-9_]+$/.test(key)) {
        console.error('Invalid URL parameter name:', key);
        return; // Skip this parameter
      }
      
      if (value) {
        // For array values, validate each item
        if (Array.isArray(value)) {
          const sanitizedValue = value
            .filter(item => typeof item === 'string')
            .join(',');
          url.searchParams.set(key, sanitizedValue);
        } else if (typeof value === 'string') {
          url.searchParams.set(key, value);
        } else {
          // Convert to string if not already
          url.searchParams.set(key, String(value));
        }
      } else {
        url.searchParams.delete(key);
      }
    });
    
    const newUrl = url.toString();
    
    // Update URL without page reload
    window.history.replaceState(null, '', newUrl);
  } catch (error) {
    console.error('Error updating URL parameters:', error);
  }
}

// Helper function to clear all project filter URL parameters
export const clearProjectFilterUrlParams = () => {
  try {
    const url = new URL(window.location.href);
    
    // Clear all project filter-related parameters
    url.searchParams.delete('project_tags');
    url.searchParams.delete('project_tags_mode');
    url.searchParams.delete('project_sort');
    url.searchParams.delete('exclude_project_ids');
    
    // Update URL without reloading the page
    window.history.replaceState(null, '', url.toString());
  } catch (error) {
    console.error('Error clearing URL parameters:', error);
  }
}

// Function to apply project filters from URL parameters
// This can be called at app initialization time
export const applyProjectFiltersFromUrl = (dispatch, search = window.location.search) => {
  try {
    const params = new URLSearchParams(search)
    let appliedAnyFilter = false
    
    // Apply to both PROJECTS and PROJECTS_PANEL filter types to ensure consistent state
    const filterTypes = [FILTER_REDUCER_KEYS.PROJECTS, FILTER_REDUCER_KEYS.PROJECTS_PANEL];
    
    // Project tags
    const projectTags = params.get(queryParamsHelper.QUERY_PARAMS.PROJECT_TAGS)
    if (projectTags) {
      // Validate: only allow alphanumeric characters, dashes, and underscores in tag names
      const tagArray = projectTags.split(',')
        .map(tag => tag.trim())
        .filter(tag => /^[a-zA-Z0-9_-]+$/.test(tag));
      
      // Only proceed if we have valid tags after filtering
      if (tagArray.length > 0) {
        // Apply to both filter types
        filterTypes.forEach(filterType => {
          // First clear any existing tags to make sure we're starting fresh
          dispatch(setProjectsTagsFilter([], filterType))
          
          // Then set the new tags from the URL
          dispatch(setProjectsTagsFilter(tagArray, filterType))
        });
        
        appliedAnyFilter = true
      }
    }
    
    // Project tags mode
    const projectTagsMode = params.get(queryParamsHelper.QUERY_PARAMS.PROJECT_TAGS_MODE)
    if (projectTagsMode) {
      // Apply to both filter types
      filterTypes.forEach(filterType => {
        dispatch(setProjectsTagsModeFilter(projectTagsMode, filterType))
      });
      
      appliedAnyFilter = true
    }
    
    // Project sort type
    const projectSort = params.get(queryParamsHelper.QUERY_PARAMS.PROJECT_SORT)
    if (projectSort) {
      // Strictly validate against a whitelist of allowed sort types
      const validSortTypes = ['name', 'index', 'timestamp', 'starred', 'pinned']
      if (validSortTypes.includes(projectSort)) {
        // Apply to both filter types
        filterTypes.forEach(filterType => {
          dispatch(setProjectsSortType(projectSort, filterType))
        });
        
        appliedAnyFilter = true
      } else {
        console.warn('Ignored invalid sort parameter:', projectSort);
      }
    }
    
    // Force UI update if needed
    if (appliedAnyFilter) {
      setTimeout(() => window.dispatchEvent(new Event('filterchange')), 100)
    }
    
    return appliedAnyFilter
  } catch (error) {
    console.error('Error applying project filters from URL:', error)
    return false
  }
}

export const useFilterTagsFromParams = (isProjectFilter = false) => {
  const store = useStore()
  const state = store.getState()
  const dispatch = useDispatch()
  const location = useLocation()
  const searchQueryTags = queryParamsHelper.getSearchParamsByTag()
  const history = useHistory()
  const setFilter = isProjectFilter ? setProjectsTagsFilter : setItemsTagsFilter
  const getFilter = isProjectFilter ? getProjectsTagsFilter : getItemsTagsFilter

  useEffect(() => {
    const tagsFilter = getFilter(state, isProjectFilter ? FILTER_REDUCER_KEYS.PROJECTS : FILTER_REDUCER_KEYS.SEARCH)
    const setTagsFilter = bindActionCreators(
      _.partialRight(setFilter, isProjectFilter ? FILTER_REDUCER_KEYS.PROJECTS : FILTER_REDUCER_KEYS.SEARCH),
      dispatch
    )

    const onInsertTagFilter = tagName => {
      const currentTagNames = tagsFilter.toArray()
      const newTagNames = _.concat(currentTagNames, tagName)
      setTagsFilter(newTagNames)
    }
    const queryParams = new URLSearchParams(location.search)

    if (searchQueryTags && !_.includes(tagsFilter.toArray(), searchQueryTags)) {
      onInsertTagFilter(searchQueryTags)
      queryParams.delete('tag_filter')
      history.replace({
        search: queryParams.toString(),
      })
    }
  }, [dispatch, getFilter, history, isProjectFilter, location.search, searchQueryTags, setFilter, state])
}

/**
 * Hook for handling item state filter with URL updates
 * @param {function} dispatch - Redux dispatch function
 * @param {string} filterType - Filter type key, default SEARCH
 * @returns {Object} State filter functions
 */
export const useStateFilter = (dispatch, filterType = FILTER_REDUCER_KEYS.SEARCH) => {
  // Get current state filter from Redux
  const stateFilter = useSelector(state => getItemsStateFilter(state, filterType))
  
  // Function to update state filter and URL
  const setStateFilter = useCallback((stateFilterValue) => {
    try {
      // Update Redux state
      dispatch(setItemsStateFilter(stateFilterValue, filterType))
      
      // Map state filter enum to human-readable values
      let stateValue = null
      if (stateFilterValue === STATE_FILTER.FINISHED) {
        stateValue = 'finished'
      } else if (stateFilterValue === STATE_FILTER.UNFINISHED) {
        stateValue = 'unfinished'
      } else if (stateFilterValue === STATE_FILTER.ALL_ITEMS) {
        stateValue = 'all'
      }
      
      // Update URL parameter
      updateMultipleUrlParams({ 'item_state': stateValue })
    } catch (error) {
      console.error('Error updating state filter or URL:', error)
    }
  }, [dispatch, filterType])
  
  return {
    stateFilter,
    setStateFilter
  }
}

/**
 * Hook for managing tags filter with URL updates
 */
export const useTagsFilter = (dispatch, filterType = FILTER_REDUCER_KEYS.SEARCH) => {
  // Get current tags from Redux
  const tagsFilter = useSelector(state => getItemsTagsFilter(state, filterType))
  
  // Function to update tags filter and URL
  const setTagsFilter = useCallback((tags) => {
    try {
      // Update Redux store
      dispatch(setItemsTagsFilter(tags, filterType))
      
      // Get URL
      const url = new URL(window.location.href)
      
      // Handle array or immutable collection
      let tagsArray = []
      if (tags) {
        if (Array.isArray(tags)) {
          tagsArray = tags
        } else if (typeof tags.toArray === 'function') {
          tagsArray = tags.toArray()
        }
      }
      
      // Update URL
      if (tagsArray.length > 0) {
        updateUrlParam(url, 'tags', tagsArray.join(','))
      } else {
        updateUrlParam(url, 'tags', null)
      }
    } catch (error) {
      console.error('Error updating tags filter or URL:', error)
    }
  }, [dispatch, filterType])
  
  return {
    tagsFilter,
    setTagsFilter
  }
}

/**
 * Hook for managing quadrant filter with URL updates
 */
export const useQuadrantFilter = (dispatch, filterType = FILTER_REDUCER_KEYS.SEARCH) => {
  // Get current quadrant from Redux
  const quadrantFilter = useSelector(state => getItemsQuadrantFilter(state, filterType))
  
  // Function to update quadrant filter and URL
  const setQuadrantFilter = useCallback((quadrant) => {
    try {
      // Update Redux store
      dispatch(setItemsQuadrantFilter(quadrant, filterType))
      
      // Get URL
      const url = new URL(window.location.href)
      
      // Update URL
      if (quadrant !== null && quadrant > -1) {
        updateUrlParam(url, 'quadrant', quadrant.toString())
      } else {
        updateUrlParam(url, 'quadrant', null)
      }
    } catch (error) {
      console.error('Error updating quadrant filter or URL:', error)
    }
  }, [dispatch, filterType])
  
  return {
    quadrantFilter,
    setQuadrantFilter
  }
}

/**
 * Hook for managing owners filter with URL updates
 */
export const useOwnersFilter = (dispatch, filterType = FILTER_REDUCER_KEYS.SEARCH) => {
  // Get current owners from Redux
  const ownersFilter = useSelector(state => getItemsOwnersFilter(state, filterType))
  
  // Function to update owners filter and URL
  const setOwnersFilter = useCallback((owners) => {
    try {
      // Update Redux store
      dispatch(setItemsOwnerFilter(owners, filterType))
      
      // Get URL
      const url = new URL(window.location.href)
      
      // Handle array or immutable collection
      let ownersArray = []
      if (owners) {
        if (Array.isArray(owners)) {
          ownersArray = owners
        } else if (typeof owners.toArray === 'function') {
          ownersArray = owners.toArray()
        }
      }
      
      // Update URL
      if (ownersArray.length > 0) {
        updateUrlParam(url, 'owners', ownersArray.join(','))
      } else {
        updateUrlParam(url, 'owners', null)
      }
    } catch (error) {
      console.error('Error updating owners filter or URL:', error)
    }
  }, [dispatch, filterType])
  
  return {
    ownersFilter,
    setOwnersFilter
  }
}

/**
 * Hook for managing sort mode with URL updates
 */
export const useSortModeFilter = (dispatch, filterType = FILTER_REDUCER_KEYS.SEARCH) => {
  // Get current sort settings from Redux
  const sortType = useSelector(state => getItemsSortType(state, filterType))
  const sortAscending = useSelector(state => getItemsSortAscending(state, filterType))
  
  // Function to update sort type and URL
  const setSortMode = useCallback((newSortType) => {
    try {
      // Update Redux store
      dispatch(setItemsSortType(newSortType, filterType))
      
      // Update URL parameter
      updateMultipleUrlParams({ 'sort_mode': newSortType })
    } catch (error) {
      console.error('Error updating sort mode or URL:', error)
    }
  }, [dispatch, filterType])
  
  // Function to update sort direction and URL
  const setSortDirection = useCallback((isAscending) => {
    try {
      // Update Redux store
      dispatch(setItemsSortAscending(isAscending, filterType))
      
      // Update URL parameter
      updateMultipleUrlParams({ 'sort_direction': isAscending ? 'asc' : 'desc' })
    } catch (error) {
      console.error('Error updating sort direction or URL:', error)
    }
  }, [dispatch, filterType])
  
  return {
    sortType,
    sortAscending,
    setSortMode,
    setSortDirection
  }
}

/**
 * Hook for managing text filter with URL updates
 */
export const useTextFilter = (dispatch, filterType = FILTER_REDUCER_KEYS.SEARCH) => {
  // Get current text filter from Redux
  const textFilter = useSelector(state => getItemsTextFilter(state, filterType))
  
  // Helper for importing getItemsTextFilter which is referenced in the imported code
  function getItemsTextFilter(state, type) {
    return state.getIn(['filters', type, 'items', 'text'], '')
  }
  
  // Function to update text filter and URL
  const setTextFilter = useCallback((searchText) => {
    try {
      // Update Redux store
      dispatch(setItemsTextFilter(searchText, filterType))
      
      // Get URL
      const url = new URL(window.location.href)
      
      // Update URL
      if (searchText) {
        updateUrlParam(url, 'q', searchText)
      } else {
        updateUrlParam(url, 'q', null)
      }
    } catch (error) {
      console.error('Error updating text filter or URL:', error)
    }
  }, [dispatch, filterType])
  
  return {
    textFilter,
    setTextFilter
  }
}

/**
 * Hook for managing project tags filter with URL updates
 */
export const useProjectTagsFilter = (dispatch, filterType = FILTER_REDUCER_KEYS.PROJECTS) => {
  // Get current tags from Redux
  const tagsFilter = useSelector(state => getProjectsTagsFilter(state, filterType))
  
  // Function to update tags filter and URL
  const setProjectTagsFilter = useCallback((tags) => {
    try {
      // Handle array or immutable collection
      let tagsArray = []
      if (tags) {
        if (Array.isArray(tags)) {
          tagsArray = tags
        } else if (typeof tags.toArray === 'function') {
          tagsArray = tags.toArray()
        }
      }
      
      // Update Redux store
      dispatch(setProjectsTagsFilter(tagsArray, filterType))
      
      // Update URL
      updateMultipleUrlParams({ 
        'project_tags': tagsArray.length > 0 ? tagsArray.join(',') : null 
      })
      
      // Force a re-render if needed
      window.dispatchEvent(new Event('filterchange'))
    } catch (error) {
      console.error('Error updating project tags filter or URL:', error)
    }
  }, [dispatch, filterType])
  
  return {
    tagsFilter,
    setProjectTagsFilter
  }
}

/**
 * Hook for managing project tags mode filter with URL updates
 */
export const useProjectTagsModeFilter = (dispatch, filterType = FILTER_REDUCER_KEYS.PROJECTS) => {
  // Get current tags mode from Redux
  const tagsMode = useSelector(state => getProjectsTagsModeFilter(state, filterType))
  
  // Function to update tags mode filter and URL
  const setProjectTagsModeFilter = useCallback((mode) => {
    try {
      // Update Redux store
      dispatch(setProjectsTagsModeFilter(mode, filterType))
      
      // Update URL
      updateMultipleUrlParams({ 'project_tags_mode': mode })
    } catch (error) {
      console.error('Error updating project tags mode filter or URL:', error)
    }
  }, [dispatch, filterType])
  
  return {
    tagsMode,
    setProjectTagsModeFilter
  }
}

// NOTE: The `useProjectsModeFilter` hook has been removed as its functionality
// has been consolidated into the `useProjectFilter` hook for better code organization.
// The `useProjectFilter` hook now provides a `setProjectsMode` function that handles 
// updating both the Redux state and the URL parameters.

/**
 * Hook for managing project sort mode with URL updates
 */
export const useProjectSortFilter = (dispatch, filterType = FILTER_REDUCER_KEYS.PROJECTS) => {
  // Get current sort type from Redux
  const sortType = useSelector(state => getProjectsSortType(state, filterType))
  
  // Function to update sort type and URL
  const setProjectSortFilter = useCallback((newSortType) => {
    try {
      // Update Redux store
      dispatch(setProjectsSortType(newSortType, filterType))
      
      // Update URL parameter
      updateMultipleUrlParams({ 'project_sort': newSortType || null })
      
      // Force a re-render if needed
      window.dispatchEvent(new Event('filterchange'))
    } catch (error) {
      console.error('Error updating project sort mode or URL:', error)
    }
  }, [dispatch, filterType])
  
  return {
    sortType,
    setProjectSortFilter
  }
}

export const useSearchFilterParamsEffect = (filterType = FILTER_REDUCER_KEYS.SEARCH) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const store = useStore()
  
  // Set up a subscription to filter state changes to update URL parameters
  useEffect(() => {
    // Define the listener function inside the effect
    const registerFilterChangeListener = (storeReference, filterTypeValue) => {
      let lastState = storeReference.getState();
      
      return storeReference.subscribe(() => {
        const state = storeReference.getState();
        const oldFilters = lastState.getIn(['filters', filterTypeValue, 'items']);
        const newFilters = state.getIn(['filters', filterTypeValue, 'items']);
        
        // Only update if filters actually changed
        if (oldFilters !== newFilters) {
          try {
            // Build URL parameters object with validation
            const params = {};
            
            // Text filter - sanitize by trimming and limiting length
            const text = newFilters.get('text');
            params.q = text ? text.trim().substring(0, 100) : null;
            
            // State filter - use strict whitelisting approach
            const stateFilter = newFilters.get('state');
            if (stateFilter === STATE_FILTER.FINISHED) {
              params.item_state = 'finished';
            } else if (stateFilter === STATE_FILTER.UNFINISHED) {
              params.item_state = 'unfinished';
            } else {
              params.item_state = null;
            }
            
            // Owners filter - sanitize by validating each email
            const owners = newFilters.get('owners');
            if (owners && owners.size > 0) {
              // Basic email format validation (not perfect but a good first filter)
              const validEmails = owners.filter(email => 
                typeof email === 'string' && 
                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)
              );
              params.owners = validEmails.size > 0 ? validEmails.join(',') : null;
            } else {
              params.owners = null;
            }
            
            // Tags filter - only allow alphanumeric chars, underscore, and dash
            const tags = newFilters.get('tags');
            if (tags && tags.size > 0) {
              const validTags = tags.filter(tag => 
                typeof tag === 'string' && /^[a-zA-Z0-9_-]+$/.test(tag)
              );
              params.tags = validTags.size > 0 ? validTags.join(',') : null;
            } else {
              params.tags = null;
            }
            
            // Projects filter - handle both inclusion and exclusion modes
            const projectIds = newFilters.get('projectIds');
            const projectsMode = state.getIn(['filters', filterTypeValue, 'projectsMode']);
            
            // Clear both project-related parameters
            params.project_ids = null;
            params.exclude_project_ids = null;
            
            if (projectIds && projectIds.size > 0) {
              const validProjectIds = projectIds.filter(id => 
                typeof id === 'string' && /^[0-9]+$/.test(id)
              );
              
              if (validProjectIds.size > 0) {
                const projectIdsStr = validProjectIds.join(',');
                // Set the appropriate parameter based on the mode
                if (projectsMode === PROJECTS_MODE_KEYS.EXCLUDE) {
                  params.exclude_project_ids = projectIdsStr;
                } else {
                  params.project_ids = projectIdsStr;
                }
              }
            }
            
            // Quadrant filter - only allow numeric values 0-3
            const quadrant = newFilters.get('quadrant');
            if (quadrant !== null && quadrant > -1 && quadrant < 4 && Number.isInteger(quadrant)) {
              params.quadrant = quadrant.toString();
            } else {
              params.quadrant = null;
            }
            
            // Sort mode - use whitelist approach
            const sort = newFilters.get('sort');
            const validSortTypes = ['name', 'date', 'priority', 'timestamp', 'effort'];
            params.sort_mode = (sort && validSortTypes.includes(sort)) ? sort : null;
            
            // Sort direction - only allow specific values
            const sortAscending = newFilters.get('sortAscending');
            params.sort_direction = sortAscending !== undefined ? 
              (sortAscending ? 'asc' : 'desc') : null;
            
            // Update all parameters at once
            updateMultipleUrlParams(params);
          } catch (error) {
            console.error('Error updating URL from filter changes:', error);
          }
        }
        
        lastState = state;
      });
    };
    
    // Use the local function
    const unsubscribe = registerFilterChangeListener(store, filterType);
    return () => unsubscribe();
  }, [store, filterType]);
  
  // This effect handles URL parameters for search filters
  useEffect(() => {
    try {
      // Use a simpler approach to avoid URLSearchParams issues
      // Handle search query (q) parameter
      const searchQuery = queryParamsHelper.getSearchQuery()
      if (searchQuery) {
        dispatch(setItemsTextFilter(searchQuery, filterType))
      }
      
      // Handle item state parameter
      const itemState = queryParamsHelper.getItemState()
      if (itemState !== null) {
        // Map numeric state constants to enum values
        let stateFilterValue = STATE_FILTER.ALL_ITEMS
        if (itemState === itemHelper.STATE.DONE) {
          stateFilterValue = STATE_FILTER.FINISHED
        } else if (itemState === itemHelper.STATE.UNFINISHED) {
          stateFilterValue = STATE_FILTER.UNFINISHED
        }
        
        dispatch(setItemsStateFilter(stateFilterValue, filterType))
      }
      
      // Handle owners parameter
      const owners = queryParamsHelper.getOwners()
      if (owners) {
        dispatch(setItemsOwnerFilter(owners, filterType))
      }
      
      // Handle tags parameter
      const tags = queryParamsHelper.getTags()
      if (tags) {
        dispatch(setItemsTagsFilter(tags, filterType))
      }
      
      // Handle project_ids parameter (inclusion mode)
      const projectIds = queryParamsHelper.getProjectIds()
      if (projectIds && projectIds.length > 0) {
        try {
          dispatch(setItemProjectFilter(projectIds, filterType))
          dispatch(setItemProjectsMode(PROJECTS_MODE_KEYS.INCLUDE, filterType))
        } catch (error) {
          console.error("Error setting project include filter:", error)
        }
      }
      
      // Handle exclude_project_ids parameter (exclusion mode)
      const excludeProjectIds = queryParamsHelper.getExcludeProjectIds()
      if (excludeProjectIds && excludeProjectIds.length > 0) {
        try {
          dispatch(setItemProjectFilter(excludeProjectIds, filterType))
          dispatch(setItemProjectsMode(PROJECTS_MODE_KEYS.EXCLUDE, filterType))
        } catch (error) {
          console.error("Error setting project exclude filter:", error)
        }
      }
      
      // Handle quadrant parameter
      const quadrant = queryParamsHelper.getQuadrant()
      if (quadrant !== null) {
        dispatch(setItemsQuadrantFilter(quadrant, filterType))
      }
      
      // Handle sort mode parameter
      const sortMode = queryParamsHelper.getSortMode()
      if (sortMode) {
        dispatch(setItemsSortType(sortMode, filterType))
      }
      
      // Handle sort direction parameter
      const sortDirection = queryParamsHelper.getSortDirection()
      if (sortDirection !== null) {
        dispatch(setItemsSortAscending(sortDirection, filterType))
      }
    } catch (error) {
      console.error('Error processing URL parameters:', error)
    }
  }, [dispatch, location.search, filterType]);
}

/**
 * Converts any collection (Immutable or array) to a JavaScript array
 * @param {Array|Immutable.Collection} collection - The collection to convert
 * @returns {Array} The collection as a JavaScript array
 */
export const toJsArray = (collection) => {
  if (!collection) return [];
  if (Array.isArray(collection)) return collection;
  if (typeof collection.toArray === 'function') return collection.toArray();
  if (typeof collection.size === 'number') {
    const result = [];
    for (let i = 0; i < collection.size; i++) {
      result.push(collection.get(i));
    }
    return result;
  }
  return Array.from(collection);
};

// Enhanced setItemProjectsMode that also updates URL parameters
export const setItemProjectsModeWithUrl = (mode, projectIds, filterType = FILTER_REDUCER_KEYS.SEARCH) => {
  return (dispatch, _getState) => {
    // First dispatch the regular action to update Redux state
    dispatch(setItemProjectsMode(mode, filterType));
    
    // Only update URL if we have project IDs
    if (projectIds && (Array.isArray(projectIds) ? projectIds.length > 0 : projectIds.size > 0)) {
      try {
        const url = new URL(window.location.href);
        
        // Convert to array using our utility function
        const projectIdsArray = toJsArray(projectIds);
        const projectIdsString = projectIdsArray.join(',');
        
        // Clear both parameters first to avoid having both in the URL
        url.searchParams.delete(queryParamsHelper.QUERY_PARAMS.PROJECT_IDS);
        url.searchParams.delete(queryParamsHelper.QUERY_PARAMS.EXCLUDE_PROJECT_IDS);
        
        // Set the appropriate parameter based on the mode
        if (mode === PROJECTS_MODE_KEYS.EXCLUDE) {
          url.searchParams.set(queryParamsHelper.QUERY_PARAMS.EXCLUDE_PROJECT_IDS, projectIdsString);
        } else {
          url.searchParams.set(queryParamsHelper.QUERY_PARAMS.PROJECT_IDS, projectIdsString);
        }
        
        // Update URL without reloading
        window.history.replaceState(null, '', url.toString());
      } catch (error) {
        console.error('Error updating project mode URL parameter:', error);
      }
    }
  };
};

export const useProjectFilterParamsEffect = (filterType = FILTER_REDUCER_KEYS.PROJECTS) => {
  const dispatch = useDispatch()
  const location = useLocation()
  
  // This effect handles URL parameters for project filters
  useEffect(() => {
    // Only apply if URL has project_tags or project_sort
    const params = new URLSearchParams(location.search);
    if (params.has('project_tags') || params.has('project_sort')) {
      // Apply immediately on location change
      applyProjectFiltersFromUrl(dispatch, location.search)
      
      // Try multiple times with increasing delays to ensure it takes effect
      const timers = [
        setTimeout(() => {
          applyProjectFiltersFromUrl(dispatch, location.search);
          window.dispatchEvent(new Event('filterchange'));
        }, 300),
        
        setTimeout(() => {
          applyProjectFiltersFromUrl(dispatch, location.search);
          window.dispatchEvent(new Event('filterchange'));
        }, 800)
      ];
      
      return () => timers.forEach(timer => clearTimeout(timer));
    }
    
    // Explicitly return null for the case when no project filters are in URL
    return null;
  }, [dispatch, location.search, filterType]);
}