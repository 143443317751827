import { useCallback } from 'react'
import _ from 'lodash'
import { setItemProjectFilter, setItemProjectsMode, PROJECTS_MODE_KEYS } from '../common/src/actions/filtersActions'
import { FILTER_REDUCER_KEYS } from '../common/src/reducers/filtersKeys'
import { useSelector } from 'react-redux'
import { getItemsProjectFilter, getItemsProjectsMode } from '../common/src/selectors/filtersSelectors'
import * as queryParamsHelper from '../helpers/queryParamsHelper'
import { toJsArray, setItemProjectsModeWithUrl } from './filterHooks'

/**
 * Hook to handle project filter with URL updates
 * @param {function} dispatch - Redux dispatch function
 * @param {string} filterType - The filter reducer key, default SEARCH
 * @returns {object} - Functions to handle project filter and project mode
 */
export const useProjectFilter = (dispatch, filterType = FILTER_REDUCER_KEYS.SEARCH) => {
  // Get current projects and mode from filter state
  const projectIds = useSelector(state => getItemsProjectFilter(state, filterType))
  const projectsMode = useSelector(state => getItemsProjectsMode(state, filterType))
  
  // Function to update project filter and URL
  const setProjectFilter = useCallback((newProjectIds, mode = null) => {
    try {
      // Determine the mode if not provided
      const effectiveMode = mode !== null ? mode : projectsMode || PROJECTS_MODE_KEYS.INCLUDE
      
      // First dispatch the actions to update Redux state
      dispatch(setItemProjectFilter(newProjectIds, filterType))
      dispatch(setItemProjectsMode(effectiveMode, filterType))
      
      // Update URL with appropriate parameter based on mode
      const url = new URL(window.location.href)
      
      // Convert to an array using our utility function
      const projectIdsArray = toJsArray(newProjectIds)
      
      // Clear both parameters first to avoid having both in the URL
      url.searchParams.delete(queryParamsHelper.QUERY_PARAMS.PROJECT_IDS)
      url.searchParams.delete(queryParamsHelper.QUERY_PARAMS.EXCLUDE_PROJECT_IDS)
      
      // Check if we have any project IDs to add to URL
      if (projectIdsArray && projectIdsArray.length > 0) {
        const projectIdsString = projectIdsArray.join(',')
        if (projectIdsString) {
          // Use the appropriate parameter based on the mode
          const paramName = effectiveMode === PROJECTS_MODE_KEYS.EXCLUDE 
            ? queryParamsHelper.QUERY_PARAMS.EXCLUDE_PROJECT_IDS 
            : queryParamsHelper.QUERY_PARAMS.PROJECT_IDS
          
          // Get the current search params as an object
          const searchParams = {}
          url.searchParams.forEach((value, key) => {
            searchParams[key] = value
          })
          
          // Update the project IDs parameter
          searchParams[paramName] = projectIdsString
          
          // Build a clean query string
          const queryString = Object.entries(searchParams)
            .map(([key, value]) => `${key}=${value}`)
            .join('&')
            
          // Update the URL with the clean query string
          const newUrl = `${url.origin}${url.pathname}?${queryString}`
          window.history.replaceState(null, '', newUrl)
          return // Skip the normal URL update
        }
      }
      
      // Update URL without reloading
      window.history.replaceState(null, '', url.toString())
    } catch (error) {
      console.error('Error updating project filter or URL:', error)
    }
  }, [dispatch, filterType, projectsMode])
  
  // Function to toggle project mode (include/exclude) without changing selected projects
  const setProjectsMode = useCallback((mode) => {
    // Use the enhanced action creator that also updates the URL
    dispatch(setItemProjectsModeWithUrl(mode, projectIds, filterType))
  }, [dispatch, filterType, projectIds])
  
  return {
    projectIds,
    projectsMode,
    setProjectFilter,
    setProjectsMode
  }
}